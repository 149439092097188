
import { defineComponent, PropType } from 'vue';

import { RawLocation } from 'vue-router';

import { isEmpty } from '@/helpers/lodash';

import {
  Level, LevelEnum, LevelsBox, LevelValuesMap,
} from '@/domains/multileveltest';
import { IBreadcrumb, PlayerSessionStatus } from '@/domains/common';
import { ICourse } from '@/domains/course';
import { ITestInfoGetResponseData } from '@/services/api/lxp-multi-level-test/types';
import { AtomType } from '@/domains/atom';

import TTBackButton from '@/components/ui/TTBackButton.vue';
import LevelsScale from '@/components/ui/LevelsScale';
import TestRules from '@/components/ui/TestRules';

import * as LxpMultilevelService from '@/services/api/lxp-multi-level-test';
import * as CatalogueService from '@/services/api/catalogue';
import Skeleton from '@/components/ui/Skeleton.vue';

interface MultilevelTestData {
  maxLevel: LevelEnum
  name: string
  result: ITestInfoGetResponseData['result']
  atomName: string | null;
  playerSessionStatus: PlayerSessionStatus;
  answerTimeout: number;
  isTestLoading: boolean;
}

export default defineComponent({
  name: 'MultileveltestView',

  components: {
    TTBackButton,
    LevelsScale,
    TestRules,
    Skeleton,
  },

  inject: ['Names'],

  props: {
    assignmentId: {
      type: Number,
      required: true,
    },

    trackSessionId: {
      type: Number,
      required: true,
    },

    stepId: {
      type: String,
      required: true,
    },

    catalogueAtomId: {
      type: String,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    course: {
      type: Object as PropType<ICourse>,
      required: true,
    },
  },

  data(): MultilevelTestData {
    return {
      maxLevel: LevelEnum.LEVEL_NOVICE,
      name: '',
      result: {} as ITestInfoGetResponseData['result'],
      atomName: '',
      playerSessionStatus: PlayerSessionStatus.NEW,
      answerTimeout: 0,
      isTestLoading: false,
    };
  },

  computed: {
    atomType(): AtomType {
      return AtomType.MULTILEVELTEST;
    },

    breadcrumbs(): IBreadcrumb[] {
      return [
        {
          text: this.$t('LearningTracks.title'),
          to: {
            name: this.Names.R_APP_LEARNING_TRACKS,
          },
          'data-test-label': 'my-learning',
        },
        {
          text: this.course.name,
          to: {
            name: this.Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
            params: {
              assignmentId: String(this.assignmentId),
              trackSessionId: String(this.trackSessionId),
              playerSessionId: String(this.trackSessionId),
              atomType: AtomType.TRACK,
            },
          },
          'data-test-label': 'track-name',
        },
        {
          text: this.$t('LearningTracks.materials'),
          disabled: true,
          'data-test-label': 'track-materials',
        },
      ];
    },

    maxLevelValue(): number {
      return LevelValuesMap[this.maxLevel as LevelEnum] || 0;
    },

    buttonName(): string {
      switch (this.playerSessionStatus) {
        case PlayerSessionStatus.NEW:
          return this.$t('MultileveltestView.test');
        case PlayerSessionStatus.ACTIVE:
          return this.$t('MultileveltestView.continue');
        default:
          return this.$t('MultileveltestView.repeat');
      }
    },

    buttonDataLabel(): string {
      switch (this.playerSessionStatus) {
        case PlayerSessionStatus.NEW:
          return 'start-test';
        case PlayerSessionStatus.ACTIVE:
          return 'continue-test';
        default:
          return 'repeat-test';
      }
    },

    currentLevel(): Level {
      return this.hasResult
        ? LevelsBox.find(({ type }) => type === this.result?.level) as Level
        : LevelsBox[0];
    },

    hasResult(): boolean {
      if (isEmpty(this.result)) { return false; }

      return Boolean(this.result);
    },

    resultRoute(): RawLocation {
      return {
        name: this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_RESULT,
        params: {
          assignmentId: String(this.assignmentId),
          trackSessionId: String(this.trackSessionId),
          stepId: this.stepId,
          playerSessionId: String(this.playerSessionId),
        },
      };
    },

    userId(): string {
      return this.$route.params.userId ?? '';
    },

    playerSessionId(): number {
      return Number(this.$route.params.playerSessionId);
    },
  },

  watch: {
    catalogueAtomId: {
      handler(id) {
        if (id) {
          this.atomGet(id);
        }
      },
      immediate: true,
    },
  },

  async created() {
    await this.testInfoGet();
  },

  methods: {
    async testInfoGet() {
      try {
        this.isTestLoading = true;

        const {
          maxLevel, name, result, playerSessionStatus, answerTimeout,
        } = await LxpMultilevelService.testInfoGet({
          playerSessionId: this.playerSessionId,
        });

        if (!isEmpty(result)) {
          this.goToResultsPage();
        }

        this.result = result;
        this.playerSessionStatus = playerSessionStatus;
        this.maxLevel = maxLevel;
        this.name = name;
        this.answerTimeout = answerTimeout;
      } catch (e: any) {
        console.error(e);
        this.$di.notify.error({ content: this.$t('MultileveltestView.error') });
      } finally {
        this.isTestLoading = false;
      }
    },

    async testSessionRun() {
      const { status } = await LxpMultilevelService.testSessionRun({
        playerSessionId: this.playerSessionId,
      });

      if ([PlayerSessionStatus.ACTIVE, PlayerSessionStatus.COMPLETED].includes(status)) {
        this.$router.push({
          name: this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_QUESTION,
          params: {
            assignmentId: String(this.assignmentId),
            trackSessionId: String(this.trackSessionId),
            stepId: this.stepId,
            playerSessionId: String(this.playerSessionId),
          },
        });
      } else {
        this.$router.push({
          name: this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_RESULT,
          params: {
            assignmentId: String(this.assignmentId),
            trackSessionId: String(this.trackSessionId),
            stepId: this.stepId,
            playerSessionId: String(this.playerSessionId),
          },
        });
      }
    },

    async atomGet(id: string) {
      const { name } = await CatalogueService.atomGet(id);
      this.atomName = name;
    },

    goToResultsPage() {
      this.$router.push(this.resultRoute);
    },
  },
});
